import { isNaN } from "lodash";
import { ErrorMessageBackendDataShape } from "../../types";

export const addAlphatoHexColor = (color: string, opacity: number): string => {
  const newOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + newOpacity.toString(16).toUpperCase();
};

export function convertToEncodedURL(obj: { [key: string]: any }): string {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");
}

export function isDev() {
  return process.env.NODE_ENV === "development";
}

export function isBackendErrorItem(e: any): e is ErrorMessageBackendDataShape {
  const check = e as ErrorMessageBackendDataShape;
  return !!check?.data?.message;
}

export function formatIDR(n: number) {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  if (isNaN(n)) return "-";

  return formatter.format(n);
}
/**
 * @description This function is used to transform string "true" to boolean values
 * @param {string} value - "true" or "false"
 * @returns {Boolean | undefined}
 */
export const stringToBoolean = (value: any): boolean | undefined => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
};

/**
 * @description Function to remove an entry by key
 * @param obj
 * @param keyToRemove
 * @returns
 */
export const removeObjectEntryByKey = <D extends Record<string, any>>(
  obj: D,
  keysToRemove: Array<keyof D>,
): D => {
  // Use destructuring to create a copy of the object without the specified key
  const newObject = { ...obj };
  keysToRemove.forEach((key) => delete newObject[key]);

  // Optionally, you can use the delete keyword to remove the entry directly
  // delete obj[keyToRemove];

  return newObject;
};

/**
 * @description "Melakukan formatting string ke nomor telepon"
 * @param phoneNumber
 * @param isFormat - "Jika true maka nomor telepon akan di format"
 * @returns
 */
export const formatPhoneNumber = (
  phoneNumber: string,
  isFormat: boolean = true,
): string => {
  // Remove all non-digit characters from the phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  if (isFormat) {
    // Check if the cleaned number starts with the local area code ('0')
    if (cleanedNumber.startsWith("0")) {
      // Insert spaces after the second and sixth digit
      return cleanedNumber.replace(/(\d{4})(\d{4})(\d+)/, "$1 $2 $3");
    }

    // Check if the cleaned number starts with the country code ('62')
    if (cleanedNumber.startsWith("62")) {
      // Remove the country code and insert a space after the second digit
      return cleanedNumber.replace(/^62/, "0").replace(/(\d{2})(\d+)/, "$1 $2");
    }
  }

  // If the number doesn't start with the country code or local area code, return it as is
  return cleanedNumber;
};

export const formatUTCToLocalDate = (date: string): Date => {
  const offsetMilisecondFromUTC = new Date(date).getTimezoneOffset() * 60000;
  const localDate = new Date(date).getTime() - offsetMilisecondFromUTC;

  return new Date(localDate);
};
