import React, { useRef } from "react";
import Portal from "components/Portal/Portal";
import { CSSTransition } from "react-transition-group";
import styled, { createGlobalStyle } from "styled-components";
import { BasicActionBtn, BasicHeader } from "components/base";
import { GrClose } from "react-icons/gr";
import { small } from "assets/theme";

type Props = {
  isShown: boolean;
  toggle: (prev: boolean) => void;
  className?: string;
  withCloseIcon?: boolean;
  headerText: string;
  zIndex?: number;
  children?: React.ReactNode;
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9997;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  &.show {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: white;
  padding: 20px;
  border-radius: 8px;
  height: min-content;
  width: min-content;
  // min-width: 380px;
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;
  z-index: 9998;

  ${small} {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 3px solid white; /* Border around thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px; /* Border around track */
  }
`;

const CloseBtn = styled(BasicActionBtn)`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 5px;
  border-radius: 4px;
  font-weight: 12px;
  line-height: 12px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
`;

const ChildrenContainer = styled.div`
  flex: 1;
`;

const IconWrapper = styled.div`
  svg {
    color: #717171;
    :hover {
      color: #000;
    }
  }
`;

const Modal: React.FC<Props> = ({
  isShown,
  toggle,
  className,
  withCloseIcon = true,
  headerText,
  children,
}) => {
  const layoutRef = useRef<HTMLDivElement>(null);

  return (
    <Portal>
      <CSSTransition
        classNames="fade"
        in={isShown}
        timeout={300}
        nodeRef={layoutRef}
        unmountOnExit
      >
        <>
          <GlobalStyle />
          <ModalContent
            className={["transition-element", className, "layout"]
              .filter((v) => !!v)
              .join(" ")}
            ref={layoutRef}
          >
            <HeaderContainer className="header-container">
              {withCloseIcon && (
                <CloseBtn
                  onClick={() => {
                    toggle(false);
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <IconWrapper>
                    <GrClose />
                  </IconWrapper>
                </CloseBtn>
              )}
              <BasicHeader
                style={{
                  textAlign: "center",
                  paddingBottom: "12px",
                  fontSize: "24px",
                }}
              >
                {headerText}
              </BasicHeader>
            </HeaderContainer>
            <ChildrenContainer className="children-container">
              {children}
            </ChildrenContainer>
          </ModalContent>
          <Backdrop
            className={isShown ? "show" : ""}
            onClick={() => {
              toggle(false);
            }}
          />
        </>
      </CSSTransition>
    </Portal>
  );
};

export default Modal;
