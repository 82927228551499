import { useEffect, useMemo, useRef, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import { CustomPaginationProps } from "types/table/pagination";

import { defaultPaginationSize } from "components/BasicTable/DefaultPaginationTable/DefaultPaginationTable";

import useDebounce from "hooks/utils/useDebounce";

import { COLORS } from "assets/theme";

import {
  CustomPaginationWrapper,
  StyledButtonNextAndPreviousWrapper,
  StyledPageDescription,
  StyledPaginationButton,
  StyledPaginationInput,
  StyledPaginationSelect,
} from "./CustomPagination.style";

const CustomPaginationTable = (props: CustomPaginationProps) => {
  const inputId = `input-pagination-${Math.random()
    .toString(36)
    .substring(2, 15)}`;
  const selectId = `select-pagination-${Math.random()
    .toString(36)
    .substring(2, 15)}`;

  const {
    page = 1,
    setLimit,
    setPage,
    take: limit = 10,
    meta,
    hasilText,
  } = props;

  const { pageCount } = meta;
  const [internalPage, setInternalPage] = useState<number | undefined>(page);
  const [renderButton, setRenderButton] = useState<number[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const debouncePage = useDebounce(internalPage);

  useEffect(() => {
    if (typeof debouncePage === "number") {
      setPage((prev) => {
        return prev !== debouncePage ? debouncePage : prev;
      });
    } else {
      setInternalPage(page);
    }
  }, [debouncePage]);

  useEffect(() => {
    if (page && page < 1) {
      setPage(1);
    }
  }, [page]);

  const customSetPage = (v: number) => {
    setPage(v);
    setInternalPage(v);
  };

  const pageSizes = useMemo(() => {
    const arr = defaultPaginationSize.slice();
    const found = arr.find((v) => v === limit);
    if (!found) {
      arr.push(limit);
      arr.sort((a, b) => a - b);
    }
    return arr;
  }, [limit]);

  useEffect(() => {
    if (typeof pageCount !== "number") return;

    const arr = [];

    if (pageCount === 0) {
      setRenderButton([1]);
      return;
    }

    if (pageCount <= 4) {
      for (let i = 1; i <= pageCount; i++) {
        arr.push(i);
      }
    } else {
      if (page <= 2) {
        for (let i = 1; i <= 3; i++) {
          arr.push(i);
        }
        arr.push(-1);
        arr.push(pageCount);
      } else if (page >= pageCount - 1) {
        arr.push(1);
        arr.push(-1);
        for (let i = pageCount - 2; i <= pageCount; i++) {
          arr.push(i);
        }
      } else {
        arr.push(1);
        arr.push(-1);
        arr.push(page);
        arr.push(-1);
        arr.push(pageCount);
      }
    }

    setRenderButton(arr);
  }, [page, pageCount]);

  return (
    <CustomPaginationWrapper>
      <div>
        <span style={{ marginRight: 4 }}>Tampilkan&nbsp;</span>
        <StyledPaginationSelect
          id={selectId}
          value={limit}
          onChange={(e) => setLimit(Number(e.target.value))}
        >
          {pageSizes.map((pageSize, index) => (
            <option key={index} value={pageSize}>
              {pageSize} Baris
            </option>
          ))}
        </StyledPaginationSelect>
        {/* &nbsp;&nbsp; */}
        <StyledPageDescription>
          {hasilText ?? `Halaman\xa0 ${page} dari ${pageCount}\xa0`}
        </StyledPageDescription>
      </div>
      <StyledButtonNextAndPreviousWrapper>
        <StyledPaginationButton
          title="Previous"
          onClick={() => customSetPage(page - 1)}
          disabled={page <= 1}
        >
          <MdChevronLeft
            size={16}
            color={page <= 1 ? COLORS.dark_grey_1 : COLORS.text_black}
          />
        </StyledPaginationButton>

        {renderButton.map((v, index) => {
          if (v === -1) {
            return (
              v === -1 && (
                <StyledPaginationButton
                  key={index}
                  title="dot"
                  style={{
                    cursor: "default",
                    pointerEvents: "none",
                  }}
                >
                  ...
                </StyledPaginationButton>
              )
            );
          } else {
            return v === page ? (
              <span key={index}>
                <StyledPaginationInput
                  id={inputId}
                  ref={inputRef}
                  type="number"
                  value={internalPage}
                  onBlur={(e) => {
                    const val = e.target.value;
                    if (val.length === 0 || parseInt(val) === 0) {
                      setInternalPage(undefined);
                    }
                  }}
                  // min={1}
                  max={pageCount}
                  onChange={(e) => {
                    const val = e.target.value;
                    const parsed = parseInt(val);
                    if (val.length === 0 || parsed === 0)
                      setInternalPage(undefined);
                    else {
                      setInternalPage(parsed);
                    }
                  }}
                />
              </span>
            ) : (
              <StyledPaginationButton
                key={index}
                title={`${v}`}
                onClick={() => customSetPage(v)}
                disabled={page === v}
              >
                {v}
              </StyledPaginationButton>
            );
          }
        })}

        <StyledPaginationButton
          title="Next"
          onClick={() => customSetPage(page + 1)}
          disabled={page === pageCount || pageCount === 0}
        >
          <MdChevronRight
            size={16}
            color={page === pageCount ? COLORS.dark_grey_1 : COLORS.black_1}
          />
        </StyledPaginationButton>
        {/* &nbsp; &nbsp; */}
      </StyledButtonNextAndPreviousWrapper>
    </CustomPaginationWrapper>
  );
};

export default CustomPaginationTable;
