import styled, { css } from "styled-components";

import InputTextArea from "components/InputText/InputTextArea";
import { COLORS } from "assets/theme";

const inputTextCSS = css`
  & > label {
    margin-bottom: 5px;
    font-weight: normal;
    color: ${COLORS.text_black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .wrapper {
    border: 1px solid ${COLORS.neutral_gray_gull};
    border-radius: 6px;
  }

  & input,
  textarea {
    padding: 10px;
    border: none;
    font-size: 1em;

    &:focus {
      outline: 2px solid ${COLORS.green};
      outline-offset: -1.5px;
    }

    :disabled {
      background: ${COLORS.silver_lining};
    }
  }
`;

export const BaseTextAreaInput = styled(InputTextArea)`
  ${inputTextCSS}
  & textarea {
    resize: vertical;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .wrapper {
    border-radius: 6px;

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -1.5px;
    }
  }
`;
