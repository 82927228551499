import React, { useMemo } from "react";
import { Column } from "react-table";
import { format } from "date-fns";

import {
  ReportPharmacyTransactionItem,
  ReportPharmacyTransactionList,
} from "types/api/report";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { formatUTCToLocalDate } from "assets/usefulFunctions";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";

import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportPharmacyTransactionList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const { formatRupiah } = useFormatRupiah();
  const tableData = data?.entities ?? [];

  const columns: Column<ReportPharmacyTransactionItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Waktu",
        accessor: "time",
        Cell: ({ value }) => (
          <>
            {value
              ? format(formatUTCToLocalDate(value), "dd MMM yyyy, HH:mm")
              : "-"}
          </>
        ),
      },
      {
        Header: "Nama Pasien",
        accessor: (row) => row.patient_name || "-",
      },
      {
        Header: "No. RM",
        accessor: (row) => row.encounter || "-",
      },
      {
        Header: "Merk Obat",
        accessor: (row) => row.merk || "-",
      },
      {
        Header: "Kuantitas",
        accessor: (row) => row.quantity || "-",
      },
      {
        Header: "Harga Satuan",
        accessor: "price",
        Cell: ({ value }) => <>{value ? formatRupiah(value) : "-"}</>,
      },
      {
        Header: "Harga Total",
        accessor: "total_price",
        Cell: ({ value }) => <>{value ? formatRupiah(value) : "-"}</>,
      },
    ];
  }, [page, take, formatRupiah]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
